<template>
  <div>
    <el-button
      class="filter-item"
      size="mini"
      type="primary"
      icon="el-icon-plus"
      @click="to"
      v-p="['externaladmin:user:list:add']"
      >添加分销商</el-button
    >
    <div v-p="['externaladmin:user:list:search']" class="head-container bg">
      <div class="inline-block user-component-header">
        <el-form :model="query" inline>
          <el-form-item label="分销商名称:">
            <el-input
              v-model="query.nickName"
              clearable
              placeholder="输入分销商名称"
              style="width: 200px"
              class="filter-item"
              @clear="toQuery"
              @keyup.native.enter="toQuery"
            />
          </el-form-item>
          <el-form-item label="创建时间:">
            <el-date-picker
              v-model="createTime"
              type="daterange"
              value-format="yyyy-MM-dd"
              placeholder="请选择创建时间"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 300px"
              @clear="toQuery"
              @change="toQuery"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="登录账号:">
            <el-input
              v-model="query.accountName"
              clearable
              placeholder="输入登录账号搜索"
              style="width: 200px"
              class="filter-item"
              @clear="toQuery"
              @keyup.native.enter="toQuery"
            />
          </el-form-item>
          <el-form-item label="分销商类型:">
            <el-select
              v-model="query.isProductPublic"
              placeholder="请选择分销商类型"
              @clear="toQuery"
              @change="toQuery"
              clearable
            >
              <el-option
                v-for="(item, index) in merchantType"
                :key="index"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色:">
            <el-select v-model="query.roleId" placeholder="请选择角色" @clear="toQuery" @change="toQuery" clearable>
              <el-option
                v-for="(item, index) in businessRoleList"
                :key="index"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分销商性质: ">
            <el-select v-model="query.userNature" placeholder="请选择分销商性质" @change="toQuery" clearable>
              <el-option
                v-for="item in sup_this.userNatureList"
                :value="item.value"
                :label="item.label"
                :key="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input
              v-model="query.remark"
              clearable
              placeholder="请输入备注"
              style="width: 200px"
              class="filter-item"
              @keyup.native.enter="toQuery"
              @clear="toQuery"
            />
          </el-form-item>
          <el-form-item label="是否可查看侵权公共产品: ">
            <el-select v-model="query.isCanTort" placeholder="请选择" @change="toQuery" clearable>
              <el-option
                v-for="{ value, label } in sup_this.tortList"
                :value="value"
                :label="label"
                :key="value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="toQuery" class="el-icon-search" size="mini" type="primary"></el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 新增 -->

    <eForm ref="form" v-bind="$attrs" :is-add="true" />
    <!-- 导出 -->
    <el-button
      v-if="permission.exportInfo"
      :loading="downloadLoading"
      size="mini"
      class="filter-item"
      type="primary"
      icon="el-icon-download"
      @click="download"
      >导出分销商信息</el-button
    >
  </div>
</template>

<script>
// 权限判断函数
import { getOrganizationUserTree, exportDistributorUserList } from '@/api/organization'
import cloneDeep from 'lodash/cloneDeep'
import { parseCharacterTime } from '@/utils'
import eForm from './form'
import { checkPermission } from '@/utils'
import { mapGetters } from 'vuex'
// 查询条件
export default {
  components: { eForm },
  props: {
    sup_this: {
      type: Object,
      required: true
    },
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    const permission = {
      exportInfo: checkPermission('externaladmin:user:list:exportUserInfo')
    }
    return {
      orgusers: [],
      createTime: [],
      downloadLoading: false,
      enabledTypeOptions: [
        { key: 'true', display_name: '激活' },
        { key: 'false', display_name: '锁定' }
      ],
      merchantType: [
        {
          value: undefined,
          label: '全部'
        },
        {
          value: 0,
          label: '分销商'
        },
        {
          value: 1,
          label: '公共产品上新'
        }
      ],
      permission
    }
  },
  computed: {
    ...mapGetters(['businessRoleList'])
  },
  methods: {
    // 去查询
    toQuery() {
      this.$parent.page = 1
      let params = cloneDeep(this.query)
      const tmpArr = this.createTime
      if (tmpArr?.length) {
        const timeProp = 'date_joined'
        params['createStartTime'] = parseCharacterTime(new Date(`${tmpArr[0]} 00:00:00`).toString().replace(' GMT', ''))
        params['createEndTime'] = parseCharacterTime(new Date(`${tmpArr[1]} 23:59:59`).toString().replace(' GMT', ''))
      } else {
        delete params.createStartTime
        delete params.createEndTime
      }
      this.sup_this.query = params
      this.$parent.init()
    },
    async to() {
      this.$router.push('/user/addUser')
    },
    // 导出
    getOrgUserTree() {
      getOrganizationUserTree().then((res) => {
        this.orgusers = res.detail
      })
    },
    async download() {
      this.downloadLoading = true
      try {
        this.query.orderItems = [{ asc: false, column: 'create_time' }]
        let res = await exportDistributorUserList(this.query)
        if (res instanceof Blob) {
          let url = URL.createObjectURL(res)
          let aLink = document.createElement('a')
          aLink.href = url
          aLink.setAttribute('download', '分销商信息')
          aLink.click()
          this.$message.success('操作成功')
        } else {
          this.$message.error('操作失败')
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.downloadLoading = false
      }
    },
    // 数据转换
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === 'is_active') {
            return v[j] ? '启用' : '禁用'
          } else {
            return v[j]
          }
        })
      )
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .el-form-item {
    margin-bottom: 20px;
  }
}
.bg {
  background: #f7f8fa;
  margin: 20px 0;
  padding-top: 20px;
  padding-bottom: 0;

  // .el-form--inline {
  //   height: 40px;
  // }

  .user-component-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }
}
</style>

import { checkPermission } from '@/utils'

export const moreThanArr = [
  {
    label: '注销用户',
    prop: 'logout',
    root: checkPermission('externaladmin:user:list:morethanBtn:cancellation'),
    color: 'danger'
  },
  {
    label: '解冻',
    prop: 'thaw',
    root: checkPermission('externaladmin:user:list:morethanBtn:thaw')
  },
  {
    label: '警告/冻结',
    prop: 'frozen',
    root: checkPermission('externaladmin:user:list:morethanBtn:warningAndFreeze'),
    color: 'danger'
  }
]

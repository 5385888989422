<template>
  <ColorTextBtn type="primary" class="line" @click="to">分销商信息</ColorTextBtn>
</template>

<script>
import { radioGroupBtn } from '@/views/user/resellerPage/resellerPageData'

export default {
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {}
  },
  computed: {
    isAuthRoute() {
      return !!radioGroupBtn.length
    }
  },
  methods: {
    to() {
      if (!this.isAuthRoute) return this.$message.error('暂无访问权限，请联系管理员添加！')
      let { id, prototypePermissionType, imagePermissionType, nickName, paymentAccountSimple } = this.data
      this.$router.push({
        path: '/user/resellerPage',
        query: {
          id,
          prototypePermissionType,
          imagePermissionType,
          nickName,
          memberLevelId: paymentAccountSimple?.memberLevelId
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>

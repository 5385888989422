<template>
  <div class="line inline-block">
    <ColorTextBtn size="mini" class="button" type="primary" @click="dialog = true">更换密码</ColorTextBtn>
    <BaseDialog :dialogVisible.sync="dialog" :title="title" width="500px" @close="cancel">
      <el-form ref="form" :model="form" :rules="rules" size="small" label-width="88px">
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="form.newPassword"
            placeholder="请输入新密码"
            type="password"
            auto-complete="on"
            style="width: 370px"
            id="newPassword"
            show-password
            clearable
            v-pwd-off
          />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            v-model="form.confirmPassword"
            type="password"
            placeholder="请输入确认密码"
            auto-complete="on"
            style="width: 370px"
            id="confirmPassword"
            show-password
            clearable
            v-pwd-off
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="default" @click="cancel">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { isRobust } from '@/utils/validate'
import { resetPassword as updatePasswd } from '@/api/user'

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    // index.vue 的this 可用于刷新数据
    sup_this: {
      type: Object,
      required: true
    }
  },
  data() {
    const confirmPass = (rule, value, callback) => {
      if (this.form.newPassword !== value) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      dialog: false,
      title: '修改密码',
      form: { newPassword: '', confirmPassword: '' },
      rules: {
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            validator: isRobust
          }
        ],
        confirmPassword: [{ required: true, validator: confirmPass, trigger: 'blur' }]
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const { newPassword, confirmPassword } = this.form || {}
          this.loading = true
          const form = {
            newPassword,
            confirmPassword,
            id: this.data.id
          }
          updatePasswd(form)
            .then((res) => {
              this.resetForm()
              this.$message({
                showClose: true,
                type: 'success',
                message: '密码修改成功!',
                duration: 2500
              })
              this.sup_this.init()
            })
            .catch((err) => {
              this.loading = false
              console.log(err)
            })
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = { newPassword: '', confirmPassword: '' }
    },
    removeAttr(idName) {
      document.getElementById(idName).addEventListener('click', this.handleClick)
      document.getElementById(idName).addEventListener('keydown', this.handleKeydown)
      document.getElementById(idName).addEventListener('mousedown', this.handleMousedown)
      //使用setTimeout，告诉JS是异步执行，这样子，就可以阻止第一次点击获取焦点时，下拉用户密码清
      //单的框的出现
      setTimeout(() => {
        //获取焦点时 同时去除只读，这样可以获取光标，进行输入
        document.getElementById(idName).removeAttribute('readonly')
      }, 300)
    },
    setAttr(idName) {
      //失去焦点立马更新为只读
      document.getElementById(idName).setAttribute('readonly', 'true')
    },
    handleClick(e) {
      //为什么先失去焦点，在获取焦点，这样子可以避免第二次或更多次连续点击输入框时，出现的用户密
      // 码清单的框可以快速去除
      if (e.type === 'click') {
        document.getElementById(e.target.id).blur()
        document.getElementById(e.target.id).focus()
      }
    },
    handleKeydown(e) {
      if (e.type === 'keydown') {
        const keyCode = e.keyCode
        const passwordText = document.getElementById(e.target.id)
        if ([8, 46].includes(keyCode)) {
          //backspace 和delete
          const len = passwordText.value.length
          if (len === 1) {
            passwordText.value = ''
            return false
          }
          if (e.target.selectionStart === 0 && e.target.selectionEnd === len) {
            passwordText.value = ''
            return false
          }
        }
        return true
      }
    },
    handleMousedown(e) {
      if (e.type === 'mousedown') {
        document.getElementById(e.target.id).blur()
        document.getElementById(e.target.id).focus()
      }
    }
  }
}
</script>

<style scoped></style>

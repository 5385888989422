export default [
  {
    prop: 'nickName',
    label: '分销商名称',
    width: '100',
    align: 'left'
  },
  {
    prop: 'accountName',
    label: '登录账号',
    align: 'center'
  },
  {
    prop: 'roleDTOList',
    label: '角色',
    align: 'center',
    slotName: 'rolesSlot'
  },
  {
    prop: 'isPayOrder',
    label: '是否免支付',
    slotName: 'isPayOrderSlot'
  },
  {
    prop: 'memberLevelId',
    label: '会员等级',
    slotName: 'vipSlot'
  },
  {
    prop: 'prototypePermissionType',
    label: '原型权限',
    slotName: 'prototypePermissionTypeSlot'
  },
  {
    prop: 'isDisabled',
    label: '激活状态',
    slotName: 'is_activeName'
  },
  {
    label: '分销商性质',
    prop: 'userNature',
    slotName: 'userNatureSlot'
  },
  {
    label: '是否能看到侵权公共产品',
    slotName: 'isCanTortSlot'
  },
  {
    prop: 'remark',
    label: '备注'
  },
  {
    prop: 'crmBusinessman',
    label: '关联CRM业务员'
  },
  //date_joined
  {
    prop: 'createTime',
    label: '创建时间',
    align: 'center',
    slotName: 'date_joinedSlot'
  }
]

<template>
  <div class="botton-group-box">
    <ColorTextBtn @click="logout"> 注销用户 </ColorTextBtn>
    <CancelDialog :cancelDialogVisible.sync="cancelDialogVisible"></CancelDialog>
  </div>
</template>

<script>
import { moreThanArr } from './option'
import CancelDialog from './module/cancelDialog/index.vue'
export default {
  components: {
    CancelDialog
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    init: {
      type: Function
    }
  },
  provide() {
    return {
      rowData: this.data,
      init: this.init
    }
  },
  data() {
    return {
      cancelDialogVisible: false
    }
  },
  methods: {
    logout() {
      this.cancelDialogVisible = !this.cancelDialogVisible
    }
  }
}
</script>

<style scoped lang="scss">
.botton-group-box {
  display: inline-block;
}
::v-deep {
  .more-than-btn-item.el-dropdown-menu__item {
    font-size: 12px !important;
  }
  .more-than-btn-item-danger.el-dropdown-menu__item {
    font-size: 12px !important;
    &:hover {
      color: red !important;
    }
  }
}
</style>

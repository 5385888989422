import { checkPermission } from '@/utils'

export const permission = {
  getInf: checkPermission('externaladmin:user:list:getInf'),
  disabled: checkPermission('externaladmin:user:list:disabled'),
  edit: checkPermission('externaladmin:user:list:edit'),
  changePassword: checkPermission('externaladmin:user:list:changePassword'),
  getInformation: checkPermission('externaladmin:user:list:getInformation'),
  changeVIP: checkPermission('externaladmin:user:changeVIP'),
  controlWaybillCode: checkPermission('externaladmin:user:list:controlWaybillCode'),
  updateEnabledCredit: checkPermission('externaladmin:user:list:updateEnabledCredit'),
  setTort: checkPermission('externaladmin:user:list:setTort')
}
